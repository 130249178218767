@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;700&display=swap');

* {
  font-family: 'Raleway', sans-serif;
}

html {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.center-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.center-horizontal {
  display: flex;
  justify-content: center;
}

// https://www.colourlovers.com/palette/159844/Blue_Fox
$open-water: #4878a8;
$tropical-sky: #a8d8f0;
$atlanteon: #487890;
$princess-peachy: #f07830;
$from-the-sauce: #781800;

// current theme
$primary: $open-water;
$secondary: $tropical-sky;
$tertiary: $atlanteon;
$accent-1: $princess-peachy;
$accent-2: $from-the-sauce;

// global colors
$gray-1: #4a4a4a;