@import '../styles.scss';

.board__wrap {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
}

.board__table {
    height: 100%;
    width: 100%;
    table-layout: fixed;
    border-spacing: 10px;
}

.board__win {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15vw;
}
.board__win.red {
    color: $kodenames-red;
}
.board__win.blue {
    color: $kodenames-blue;
}