@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;700&display=swap);
#root,.page__wrap{height:100%;width:100%}

*{font-family:'Raleway', sans-serif}html{width:100%;height:100%;margin:0}body{width:100%;height:100%;margin:0}#root{height:100%;width:100%}a{text-decoration:none;color:inherit}.center-vertical{display:flex;flex-direction:column;justify-content:center}.center-horizontal{display:flex;justify-content:center}.home__sidebar{padding:80px}@media (min-width: 1000px){.home__sidebar{position:fixed;box-sizing:border-box;width:40%;top:0;left:0;bottom:0}}@media (max-width: 1000px){.home__sidebar{min-height:500px}}@media (max-width: 450px){.home__sidebar{padding:40px}}.home__panel-wrap{height:100%}@media (min-width: 1000px){.home__panel-wrap{margin-left:40%;width:60%}}@media (max-width: 1000px){.home__panel-wrap{top:0;left:0;right:0;height:500px}}.home__panel{height:900px}.home__panel:nth-child(1){background-color:#4878a8}.home__panel:nth-child(2){background-color:#f07830}.home__panel:nth-child(3){background-color:#781800}.home__sidebar-portrait-wrap{margin-bottom:30px}.home__sidebar-portrait{border-radius:50%;width:250px;height:250px}.home__sidebar-title{color:#4a4a4a;font-size:35px;font-weight:600;margin-bottom:30px;text-transform:uppercase}.home__sidebar-intro{padding-left:50px;padding-right:50px;line-height:30px;margin-bottom:30px}.home__sidebar-social-links img{width:20px;padding:10px}.home__panel-title{text-transform:uppercase;font-size:30px;padding:10px;border:2px solid black;cursor:pointer}.home__panel-title:hover{background-color:rgba(0,0,0,0.1)}.home__panel-subtitle{line-height:30px;text-align:center;margin-top:30px;max-width:400px}

.banner__wrap{position:fixed;top:0;left:0;right:0;height:40px;background-color:#a1a1a1;display:flex;justify-content:space-between;padding:0px 15px;box-sizing:border-box}.banner__title{font-size:25px;font-weight:500}@media (max-width: 500px){.banner__title.center-vertical{display:none}}.banner__board-number-label{font-size:20px;padding-left:10px;padding-right:10px}@media (max-width: 700px){.banner__board-number-label.center-vertical{display:none}}.banner__board-number-input-wrap{height:100%;width:100px}@media (max-width: 700px){.banner__board-number-input-wrap{margin-left:10px}}.banner__board-number-input{background-color:transparent;border:0;outline:0px;border-bottom:1px solid black;font-size:20px;font-family:'Lato', sans-serif}.banner__board-number-input::-webkit-inner-spin-button,.banner__board-number-input::-webkit-outer-spin-button{-webkit-appearance:none;margin:0}.banner__red-remaining{font-size:30px;font-family:'Lato', sans-serif;color:#e74c59;padding:5px}.banner__blue-remaining{font-size:30px;font-family:'Lato', sans-serif;color:#4a68d8;padding:5px}.banner__button{background-color:#333333;border:none;color:white;outline:0;text-align:center;text-decoration:none;display:inline-block;font-size:16px;margin:5px 2.5px}.banner__button:hover{background-color:#555555}

.card__cell{text-align:center;text-transform:uppercase;background-color:#dedede;font-size:2.2vw}.card__cell:hover{background-color:#d1d1d1}.card__cell.R.flipped{background-color:#e74c59}.card__cell.B.flipped{background-color:#4a68d8}.card__cell.Y.flipped{background-color:#FEF354}.card__cell.G.flipped{background-color:#6e6e6e}.card__cell.R.spymaster{background-color:#e74c59}.card__cell.B.spymaster{background-color:#4a68d8}.card__cell.Y.spymaster{background-color:#FEF354}.card__cell.G.spymaster{background-color:#6e6e6e}.card__cell.R.flipped.spymaster{background-color:#66d66e}.card__cell.B.flipped.spymaster{background-color:#66d66e}.card__cell.Y.flipped.spymaster{background-color:#66d66e}.card__cell.G.flipped.spymaster{background-color:#66d66e}

.board__wrap{position:fixed;top:40px;bottom:0;left:0;right:0}.board__table{height:100%;width:100%;table-layout:fixed;border-spacing:10px}.board__win{position:absolute;z-index:1;top:0;bottom:0;right:0;left:0;background-color:rgba(0,0,0,0.6);display:flex;justify-content:center;align-items:center;font-size:15vw}.board__win.red{color:#e74c59}.board__win.blue{color:#4a68d8}

*{font-family:'Raleway', sans-serif}html{width:100%;height:100%;margin:0}body{width:100%;height:100%;margin:0}#root{height:100%;width:100%}a{text-decoration:none;color:inherit}.center-vertical{display:flex;flex-direction:column;justify-content:center}.center-horizontal{display:flex;justify-content:center}

